import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// form
import { Controller, useFormContext } from "react-hook-form";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftSelect from "../SoftSelect";

// eslint-disable-next-line react/display-name
const SelectInputField = forwardRef(({ options, placeholder, name, label, ...rest }, ref) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          height="100%"
          mb={1.5}
        >
          {label && (
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {label}
              </SoftTypography>
            </SoftBox>
          )}
          <SoftSelect
            error={error}
            {...rest}
            ref={ref}
            {...field}
            options={options}
            placeholder={placeholder}
            value={
              rest?.grouped
                ? options
                    .map((el) => el.options)
                    .flat(1)
                    .find((option) => option?.value === field.value) ||
                  rest.value ||
                  null
                : options.find((option) => option?.value === field.value) || rest.value
            }
            onChange={(val) => {
              if (rest.onSelect) rest.onSelect(val);
              return field.onChange(_.get(val, "value", null));
            }}
          />
          {error && (
            <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                {_.get(error, "message", "Unknown error")}
              </SoftTypography>
            </SoftBox>
          )}
        </SoftBox>
      )}
    />
  );
});

// Setting default values for the props of SelectInputField
SelectInputField.defaultProps = {
  label: "",
  placeholder: "Select an option",
  message: "Required",
};

// Typechecking props for the SelectInputField
SelectInputField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default SelectInputField;
