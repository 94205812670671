import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import SoftBox from "../SoftBox";
import SoftAvatar from "../SoftAvatar";
import SoftTypography from "../SoftTypography";

import SelectInputField from "./SelectInputField";

const PaymentOptionSelect = ({ name, label, required, paymentOptions, ...rest }) => {
  const options = _.map(paymentOptions, (item) => {
    return {
      value: item.id,
      label: (
        <SoftBox display="flex" py={1.5} px={2}>
          <SoftBox mr={1}>
            <SoftAvatar src={_.get(item, "logo", "")} size="sm" alt="title" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {item.name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });
  return (
    <SelectInputField
      name={name}
      label={label}
      options={[{ value: "", label: "All" }, ...options]}
      required={required}
      filterOption={(option, inputValue) =>
        option.value === "" ||
        paymentOptions
          .filter((i) => i.name.toLowerCase().includes(inputValue?.toLowerCase()))
          .some((i) => i.id === option.value)
      }
      {...rest}
    />
  );
};

PaymentOptionSelect.defaultValues = {
  required: false,
  label: "",
  options: [],
};
PaymentOptionSelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  paymentOptions: PropTypes.array,
};

export default PaymentOptionSelect;
