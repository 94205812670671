import React from "react";

//images
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import KeyIcon from "@mui/icons-material/Key";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

//pages
import OrganizationUsers from "./pages/users";
import MerchantReporting from "./pages/merchant-reporting";
import ApiKeys from "./pages/ApiKeyManagement";
import MerchantPayout from "./pages/merchant-payout";
import SettingsPage from "./pages/settings";
import PayByLink from "./pages/pay-by-link";
import { PagePermissions } from "./constants/roles";

const routes = [
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <PeopleAltIcon size="12px" />,
    route: "/users",
    noCollapse: true,
    component: <OrganizationUsers />,
    permissionsRequired: PagePermissions.OrganizationUsers,
  },
  {
    type: "collapse",
    name: "Reporting",
    key: "reporting",
    route: "/reporting",
    icon: <EqualizerIcon size="12px" />,
    noCollapse: true,
    component: <MerchantReporting />,
    permissionsRequired: PagePermissions.MerchantReporting,
  },
  {
    type: "collapse",
    name: "API Key Management",
    key: "api-key",
    route: "/api-key",
    icon: <KeyIcon size="12px" />,
    noCollapse: true,
    component: <ApiKeys />,
    permissionsRequired: PagePermissions.ApiKeys,
  },
  {
    type: "collapse",
    name: "Merchant Payout",
    key: "payout",
    route: "/payout",
    icon: <TrendingUpRoundedIcon size="12px" />,
    noCollapse: true,
    component: <MerchantPayout />,
    permissionsRequired: PagePermissions.MerchantPayout,
  },
  {
    type: "collapse",
    name: "Pay by link",
    key: "pay-by-link",
    route: "/pay-by-link",
    icon: <InsertLinkIcon size="12px" />,
    noCollapse: true,
    component: <PayByLink />,
    permissionsRequired: PagePermissions.PayByLink,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <SettingsSuggestRoundedIcon size="12px" />,
    noCollapse: true,
    component: <SettingsPage />,
    permissionsRequired: PagePermissions.Settings,
  },
];

export default routes;
