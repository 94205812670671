/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useForm } from "react-hook-form";
import { useChangePassword } from "./useAuth";
import React, { useState } from "react";
import { toastError, toastSuccess } from "../../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "./schemas/changePassword";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FormProvider from "../../components/FormProviders";
import Tooltip from "@mui/material/Tooltip";
import IllustrationLayout from "../../examples/LayoutContainers/IllustrationLayout";
import chat from "../../assets/images/chat.png";
import SoftBox from "../../components/SoftBox";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";

function ChangePassword() {
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { register, handleSubmit } = methods;
  const [showPassword, setShowPassword] = useState(false);
  const [isShowConfirmPassword, setShowConfirmPasswordState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetPasswordToken = searchParams.get("token");
  const { mutate, isLoading } = useChangePassword();

  const handleSubmitForm = (values) => {
    if (values.password === values.confirmPassword) {
      mutate(
        { password: values.password, resetPasswordToken: resetPasswordToken },
        {
          onSuccess: () => {
            navigate("/sign-in");
            toastSuccess({
              description: `Password changed successfully! 🙂`,
            });
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i];
              const eqPos = cookie.indexOf("=");
              const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }
          },
        }
      );
    } else {
      toastError({ description: "Passwords do not match try again" });
    }
  };

  return (
    <IllustrationLayout
      title="Reset Password"
      description="Enter your details below."
      illustration={{
        image: chat,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <SoftBox>
          <SoftBox
            mb={2}
            sx={{ display: "flex", alignItems: "center", gap: 2, position: "relative" }}
          >
            <Tooltip title={"Password must have at least 8 characters"} placement="top" arrow>
              <SoftInput
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                size="large"
                {...register("password")}
              />
            </Tooltip>
            {showPassword ? (
              <RemoveRedEyeOutlinedIcon
                onClick={() => setShowPassword(!showPassword)}
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-45%)",
                  cursor: "pointer",
                }}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                onClick={() => setShowPassword(!showPassword)}
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-45%)",
                  cursor: "pointer",
                }}
              />
            )}
          </SoftBox>
          <SoftBox
            mb={2}
            sx={{ display: "flex", alignItems: "center", gap: 2, position: "relative" }}
          >
            <SoftInput
              type={isShowConfirmPassword ? "text" : "password"}
              placeholder="Confirm password"
              size="large"
              {...register("confirmPassword")}
            />
            {isShowConfirmPassword ? (
              <RemoveRedEyeOutlinedIcon
                onClick={() => setShowConfirmPasswordState(!isShowConfirmPassword)}
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-45%)",
                  cursor: "pointer",
                }}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                onClick={() => setShowConfirmPasswordState(!isShowConfirmPassword)}
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-45%)",
                  cursor: "pointer",
                }}
              />
            )}
          </SoftBox>

          <SoftBox mt={2} mb={1}>
            <SoftButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              type="submit"
              disabled={isLoading}
            >
              reset password
            </SoftButton>
          </SoftBox>
          <SoftBox mt={2} mb={1}>
            <SoftButton
              variant="gradient"
              color="secondary"
              size="large"
              fullWidth
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              back
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </FormProvider>
    </IllustrationLayout>
  );
}

export default ChangePassword;
