import { Box } from "@mui/material";
import Image from "../Image";
import React from "react";
import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import SoftButton from "../../../../components/SoftButton";
import Header from "./Header";
import PaymentMethod from "./PaymentMethod";
import Language from "./Language";
import SubLogo from "./SubLogo";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { defaultTheme } from "./theme";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { useMerchantStore } from "../../../../stores/merchant";

const PaymentOptionScreen = ({ themeColorPresets }) => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  const textColor = theme.components.MuiTypography.styleOverrides.root.color;
  return (
    <Box
      sx={{
        width: "500px",
        backgroundColor: themeColorPresets,
      }}
      id="container"
      py={2}
    >
      <Header />
      <Box
        sx={{
          background: theme.body.background,
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
        p={3}
        id="body"
      >
        <PaymentMethod />

        <SoftBox id="payment-options" mt={3}>
          <SoftTypography fontWeight={"bold"} fontSize="18px" sx={{ color: textColor }}>
            Choose Payment Option
          </SoftTypography>

          {/* Payment Option */}
          <SoftBox
            id="payment-option-wrapper"
            sx={theme.paymentOptionWrapper ? theme.paymentOptionWrapper : {}}
          >
            <SoftBox
              id="options"
              sx={{
                padding: "10px",
                border: theme.body.border,
                borderRadius: "8px",
              }}
            >
              <SoftBox
                id="parent-option"
                display={"flex"}
                justifyContent={"space-between"}
                gap={2}
                alignItems={"center"}
              >
                <SoftBox
                  id="logo"
                  height={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Image alt="logo" src={"/logos/card.svg"} />
                </SoftBox>
                <SoftBox>
                  <SoftTypography fontWeight={"bold"} sx={{ color: textColor }}>
                    Card
                  </SoftTypography>
                  <SoftTypography fontWeight={400} fontSize={"14px"} sx={{ color: textColor }}>
                    You will be redirected to Card to complete your purchase securely
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  id="logo"
                  height={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {theme.paymentOption.icon.variant === "arrow" ? (
                    <ExpandLessRoundedIcon sx={{ color: textColor }} />
                  ) : (
                    <RadioButtonCheckedIcon sx={{ color: textColor }} />
                  )}
                </SoftBox>
              </SoftBox>
              <SoftBox id="child-option" gap={2} alignItems={"center"} ml={8} mt={2}>
                <SoftBox mt={2}>
                  <SoftTypography fontWeight={"bold"} fontSize="16px" sx={{ color: textColor }}>
                    Test
                  </SoftTypography>
                  <SoftTypography fontSize={"18px"} sx={{ color: textColor }}>
                    XXXX XXXX XXXX 1234
                  </SoftTypography>
                  <SoftTypography fontWeight={"regular"} fontSize="14px" sx={{ color: textColor }}>
                    Pay with test securely
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={4}>
                  <SoftTypography fontWeight={"bold"} fontSize="16px" sx={{ color: textColor }}>
                    GTest Asia
                  </SoftTypography>
                  <SoftTypography fontSize={"18px"} sx={{ color: textColor }}>
                    XXXX XXXX XXXX 1111
                  </SoftTypography>
                  <SoftTypography fontWeight={"regular"} fontSize="14px" sx={{ color: textColor }}>
                    Pay with test securely
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={4}>
                  <SoftTypography fontWeight={"bold"} fontSize="16px" sx={{ color: textColor }}>
                    Add new Card +
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* End Payment Option */}
          <SubLogo />
          {/* Submit Button */}
          <SoftBox display={"flex"} justifyContent={"center"} mt={3}>
            <SoftButton
              startIcon={
                theme.components.MuiButton.icon.display !== "none" ? <PaymentsOutlinedIcon /> : null
              }
              sx={{
                borderRadius: "32px",
                color: theme.components.MuiButton.styleOverrides.contained.color,
                fontSize: "0.9375rem",
                fontWeight: 700,
                background: theme.components.MuiButton.styleOverrides.contained.background,
              }}
              fullWidth
            >
              Pay 30 USD
            </SoftButton>
          </SoftBox>
          {/* End Submit Button */}
          <Language />
        </SoftBox>
      </Box>
    </Box>
  );
};
export default PaymentOptionScreen;

PaymentOptionScreen.propTypes = {
  themeColorPresets: PropTypes.string,
};
