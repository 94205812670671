export const colorPresets = [
  // DEFAULT
  {
    name: "default",
    main: "#000000",
  },
  // PURPLE
  {
    name: "purple",
    main: "#7635dc",
  },
  // CYAN
  {
    name: "cyan",
    main: "#1CCAFF",
  },
  // BLUE
  {
    name: "blue",
    main: "#2065D1",
  },
  // ORANGE
  {
    name: "orange",
    main: "#fda92d",
  },
  // RED
  {
    name: "red",
    main: "#FF3030",
  },

  // 	// GREEN
  {
    name: "green",
    main: "#28A745",
  },
  // 	// YELLOW
  {
    name: "yellow",
    main: "#FFD600",
  },
  // 	// PINK
  {
    name: "pink",
    main: "#F48FB1",
  },
];
