import { Box } from "@mui/material";
import React from "react";
import SoftTypography from "../../../../components/SoftTypography";
import { defaultTheme } from "./theme";
import { useMerchantStore } from "../../../../stores/merchant";

const Header = () => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  return (
    <Box
      sx={{
        borderTopRightRadius: "20px",
        borderTopLeftRadius: "20px",
        background: theme.header.background,
      }}
      pt={3}
      px={3}
      py={3}
      id="header"
    >
      {merchant.logo && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            component="img"
            src={merchant.logo}
            alt="logo"
            sx={{
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
          />
        </Box>
      )}
      <SoftTypography sx={{ fontSize: "20px", fontWeight: 700, color: "#fff" }}>
        Merchant X
      </SoftTypography>
      <SoftTypography sx={{ fontSize: "20px", fontWeight: 700, color: "#fff" }}>
        30 {theme.header.currencyVariant === "symbol" ? "$" : "EUR"}
      </SoftTypography>
    </Box>
  );
};
export default Header;
