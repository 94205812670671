export const defaultTheme = {
  palette: {
    primary: {
      main: "#00349a",
    },
    secondary: {
      main: "#5D737E",
    },
    error: {
      main: "#f44336",
    },
    background: {
      default: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    button: {
      fontWeight: "bold",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#4E4E4E",
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
    },
    MuiButton: {
      icon: {
        display: "block",
      },
      defaultProps: {
        size: "large",
      },
      styleOverrides: {
        root: {
          borderRadius: "32px",
        },
        sizeLarge: {
          padding: "12px 24px",
        },
        contained: {
          color: "#fff",
          background: "linear-gradient(90deg, #FF692E -0.03%, #F5B463 100.04%)",
          "&:hover": {
            backgroundColor: "#F5B463",
          },
        },
        outlined: {
          color: "#000",
          border: "1px solid #fff",
          "&:hover": {
            backgroundColor: "#8c8f88",
            color: "#000",
          },
        },
        startIcon: {
          marginLeft: "8px",
          marginRight: "8px",
          color: "#fff",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          color: "#4E4E4E",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "2px solid rgba(0, 0, 0, 0.12)",
          },
          "&:focus .MuiOutlinedInput-notchedOutline": {
            border: "#4E4E4E",
          },
        },
        notchedOutline: {
          borderWidth: "2px",
          border: "2px solid rgba(0, 0, 0, 0.12)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontWeight: "700",
          marginBottom: "8px",
          textTransform: "uppercase",
          color: "#4E4E4E",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#fff",
            color: "#000",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#4E4E4E",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "#4E4E4E",
        },
      },
    },
  },
  header: {
    color: "#fff",
    logo: "",
    background:
      "linear-gradient(273.33deg, #25028B -11.72%, #34AEFE 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    currencyVariant: "symbol",
  },
  body: {
    background: "#fff",
    color: "#000",
    border: "2px solid rgba(0, 0, 0, 0.12)",
  },
  paymentMethod: {
    justifyContent: "space-between",
    label: {
      fontSize: "20px",
      fontWeight: "500",
      marginBottom: "8px",
      textTransform: "uppercase",
    },
    value: {
      fontSize: "20px",
      lineHeight: "1.7",
      fontWeight: "400",
    },
  },
  countDown: {
    color: "#07bc0c",
  },
  instruction: {
    icon: {
      display: "block",
    },
    header: {
      color: "#07bc0c",
    },
  },
  status: {
    flexDirection: "column",
  },
  paymentOption: {
    icon: {
      variant: "arrow",
    },
    border: "2px solid rgba(0, 0, 0, 0.12)",
  },
  paymentDetails: {
    justifyContent: "space-between",
    input: {
      label: {
        display: "none",
      },
    },
  },
};
