import { useQuery } from "react-query";

const { default: request } = require("../../utils/request");

const ORG_MERCHANTS = "/organizations/merchants";

export const getMerchants = () => request.get(ORG_MERCHANTS);
export const useGetMerchants = () => {
  return useQuery([ORG_MERCHANTS], getMerchants, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
