import _ from "lodash";

export const sanitizeRequest = ({
  merchantId,
  fromDate,
  toDate,
  brandId,
  limit,
  page,
  status,
  transactionId,
  paymentOptionId,
  merchantReference,
  email,
  searchArchives,
}) => {
  const identityObj = _.pickBy(
    {
      email,
      brandId,
      merchantReference,
      transactionId,
      paymentOptionId,
      status,
      merchantId,
      fromDate,
      toDate,
      limit,
      page,
      searchArchives,
    },
    _.identity
  );
  return _.pickBy(identityObj, (value) => value !== "All");
};

export const emptyValues = {
  transactionId: "",
  brandId: "",
  status: "",
  paymentOptionId: "",
  merchantReference: "",
  email: "",
  fromDate: "",
  toDate: "",
};
