import React, { useEffect, useState } from "react";

import { useGetPaymentOptions, useGetReporting, useGetCsv, useGetSummary } from "./hooks";
import MerchantReportingFilters from "./components/MerchantReportingFilters";
import { useForm } from "react-hook-form";
import Customer from "./components/Customer";
import NotificationModal from "./components/NotificationModal";
import { Chip, Stack, Grid } from "@mui/material";
import { countries } from "../../constants/countries";
import StatusChip from "../../components/StatusChip";
import DataTable from "../../examples/Tables/DataTable";
import { get, set } from "../../utils/localStorage";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import FormProvider from "../../components/FormProviders";
import { fDateTimeSuffix } from "../../utils/formatTime";
import ActionCell from "../../components/ActionCell";
import _, { reject } from "lodash";
import { getToken } from "../../utils/cache";
import RefundModal from "./components/RefundModal";
import { useNavigate } from "react-router-dom";
import { useGetBrands } from "../ApiKeyManagement/hooks";
import { saveDataToFile } from "../../utils/file";
import { updateSelectedFilters } from "../../utils/filters";
import { sanitizeRequest, emptyValues } from "./schemas";
import TransactionsTabs from "./components/TransactionsTabs";
import TabPanel from "../../components/TabPanel";
import CurrencyReport from "./components/CurrencyReport";
import { getMerchant } from "../../utils/localStorage";

const LC_PAYLOAD = "merchantReportingPayload";
const ACTION_CELL = [
  {
    type: "visibility",
    title: "Transaction Details",
  },
  {
    type: "notification",
    title: "Send Notification",
  },
  {
    type: "withdrawal",
    title: "Refund",
  },
];

const ACTION = [
  {
    type: "visibility",
    title: "Transaction Details",
  },
  {
    type: "notification",
    title: "Send Notification",
  },
];

const MerchantReporting = () => {
  const token = getToken();
  const [tab, setTab] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [currentSelectedTransaction, setCurrentSelectedTransaction] = useState({});
  const [refundedModalOpen, setRefundedModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { mutate: downloadCsv } = useGetCsv();

  const [selectedFilters, setSelectedFilters] = useState({});
  // Destructuring the object of all selected filters to obtain only the necessary
  // eslint-disable-next-line no-unused-vars
  const { page, merchantId, limit, ...filters } = selectedFilters;
  const { mutate, isLoading, data } = useGetReporting();
  const { mutate: getSummary, data: summaries } = useGetSummary();

  const merchantReportingPayload = get(LC_PAYLOAD) || {};
  const merchant = getMerchant();
  const { data: paymentOptions } = useGetPaymentOptions();
  const defaultValues = merchantReportingPayload;
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset } = methods;
  const navigate = useNavigate();
  const { data: brands } = useGetBrands();
  const [brandOptionsData, setBrandOptionsData] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  const queryParams = new URLSearchParams(location.search);
  const pageParameter = queryParams.get("page");
  const limitParameter = queryParams.get("limit");

  const updateFilters = (values) => {
    updateSelectedFilters(values, setSelectedFilters, {
      paymentOptions,
      brands,
    });
  };

  useEffect(() => {
    if (brands)
      setBrandOptionsData(
        [{ value: "", label: "All" }].concat(
          _.map(brands, (item) => ({ value: item._id, label: item.name }))
        )
      );
  }, [brands]);
  useEffect(() => {
    if (!token) window.location.href = "/sign-in";
    if (pageParameter) {
      handlePageChange(pageParameter - 1);
    } else if (merchantReportingPayload) {
      mutate(sanitizeRequest(merchantReportingPayload));
      updateFilters(merchantReportingPayload);
    } else {
      mutate(
        sanitizeRequest({
          page: 1,
          merchantId: "All",
          limit: pageSize,
        })
      );
    }
  }, []);

  const getSelectedTransaction = (id) => {
    const transaction = _.find(_.get(data, "docs", []), { id });
    setCurrentSelectedTransaction(transaction);
  };

  const setRefundedModalClose = () => {
    setRefundedModalOpen(false);
    mutate(merchantReportingPayload);
  };

  // create a function that navigates to the detail page for a given transaction
  const handleNotificationClick = (id) => {
    getSelectedTransaction(id);
    setNotificationModalOpen(true);
  };
  const handleNotificationClose = () => {
    setNotificationModalOpen(false);
  };
  const handleRefundClick = (id) => {
    getSelectedTransaction(id);
    setRefundedModalOpen(true);
  };
  // create a function that navigates to the detail page for a given transaction
  const handleDetailClick = (id) => {
    navigate(`/reporting/${id}?txR=true`);
  };

  const removeQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("page");
    if (limitParameter) queryParams.delete("limit");
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };
  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(_.get(data, "docs", []), (item) => ({
      country: _.get(_.find(countries, { code: item.country }), "label", "N/A"),
      currency: item.currency,
      brand: item.brand,
      extra1: item.extra1,
      extra2: item.extra2,
      amount: item.amount,
      paymentOption: item.paymentOption,
      status: <StatusChip label={item.status} size="small" tooltip={item.errorReason} />,
      type: item.type,
      merchantReference: item.merchantReference,
      createdAt: fDateTimeSuffix(item.createdAt),
      customer: <Customer customer={item.customer} />,
      id: item.id,
      actions: (
        <ActionCell
          actions={item.status === "COMPLETED" ? ACTION_CELL : ACTION}
          onNotificationClick={() => handleNotificationClick(item.id)}
          onWithdrawalClick={() => handleRefundClick(item.id)}
          onVisibilityClick={() => handleDetailClick(item.id)}
        />
      ),
    }));
  };

  // create a function that handles the form submission
  const onSubmit = (values) => {
    if (pageParameter) removeQueryParam();
    const payload = {
      ...values,
      limit: pageSize,
      page: 1,
      merchantId: merchant?._id,
    };
    // eslint-disable-next-line no-unused-vars
    const { brandName, paymentOptionName, ...clearFilters } = payload;
    set(LC_PAYLOAD, payload);
    setCurrentPage(1);
    mutate(sanitizeRequest(clearFilters));
    getSummary(sanitizeRequest(clearFilters));
    updateFilters(values);
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    const payload = merchantReportingPayload;
    setCurrentPage(page + 1);
    payload.page = page + 1;
    if (limitParameter) payload.limit = limitParameter;
    set(LC_PAYLOAD, payload);
    mutate(sanitizeRequest(payload));
    if (pageParameter) removeQueryParam();
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    if (!firstRender) setCurrentPage(1);
    const payload = merchantReportingPayload;
    payload.page = 1;
    payload.limit = pageSize;
    set(LC_PAYLOAD, payload);
    updateFilters(payload);
    firstRender ? setFirstRender(false) : mutate(sanitizeRequest(payload));
  };

  const handleDownload = async () => {
    const payload = {
      ...merchantReportingPayload,
      limit: _.get(data, "pagination.totalDocs", 0),
    };
    return new Promise(function (resolve) {
      downloadCsv(payload, {
        onSuccess: async (data) => {
          await setTimeout(() => {
            saveDataToFile(data, "Transaction_Reporting_Kasha.csv", "text/csv");
            resolve();
          }, 1000);
        },
        onError: () => {
          reject();
        },
      });
    });
  };

  const onClearFilters = () => {
    set(LC_PAYLOAD, {});
    reset(emptyValues);
    setSelectedFilters({});
    onSubmit({ limit: pageSize, page: 1 });
  };

  //tab handle
  const handleTabChange = (newValue) => {
    if (data) {
      setTab(newValue);
    }
  };

  return (
    <MainLayout>
      <MainNavbar />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <MerchantReportingFilters
          brands={brandOptionsData}
          onClearFilters={onClearFilters}
          paymentOptions={paymentOptions}
        />
      </FormProvider>
      <Grid
        container
        spacing={2}
        sx={{ flexGrow: 1, marginTop: 2 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <TransactionsTabs onChange={handleTabChange} value={tab} />
      </Grid>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: 2, mb: 2 }}
        display="flex"
        flexWrap="wrap"
        gap={1}
      >
        {Object.values(filters)?.map((i, index) =>
          i ? <Chip key={i + index} label={i} color="secondary" textсolor="white" /> : null
        )}
      </Stack>
      <TabPanel value={tab} index={0}>
        <DataTable
          table={{
            columns: [
              { Header: "Brand", accessor: "brand" },
              { Header: "Country", accessor: "country" },
              { Header: "Currency", accessor: "currency" },
              { Header: "Amount", accessor: "amount" },
              { Header: "Option", accessor: "paymentOption" },
              { Header: "Extra 1", accessor: "extra1" },
              { Header: "Extra 2", accessor: "extra2" },
              { Header: "Status", accessor: "status" },
              { Header: "Type", accessor: "type" },
              { Header: "Merchant Reference", accessor: "merchantReference" },
              { Header: "Transaction ID", accessor: "id" },
              { Header: "Customer", accessor: "customer" },
              { Header: "Created time", accessor: "createdAt" },
              { Header: "Actions", accessor: "actions" },
            ],
            rows: getRows(),
          }}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageCount={_.get(data, "pagination.totalPages", 0)}
          manualPagination={true}
          rowCount={_.get(data, "pagination.totalDocs", 0)}
          isLoading={isLoading}
          columnsFilter={true}
          isDownloadable={true}
          onDownloadClick={() => handleDownload()}
          filename="Merchant_Reporting_Kasha.csv"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <CurrencyReport data={summaries} />
      </TabPanel>

      <NotificationModal
        transaction={currentSelectedTransaction}
        onClose={handleNotificationClose}
        isOpen={notificationModalOpen}
      />
      <RefundModal
        transaction={currentSelectedTransaction}
        onClose={setRefundedModalClose}
        isOpen={refundedModalOpen}
      />
    </MainLayout>
  );
};

export default MerchantReporting;
