import React from "react";
import { capitalCase } from "change-case";
import { alpha } from "@mui/material/styles";
import {
  Box,
  CardActionArea,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from "@mui/material";

import { colorPresets } from "../../../constants/colorPresets";
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import Preview from "./preview";

const colorOption = colorPresets.map((color) => ({
  name: color.name,
  value: color.main,
}));

function ColorAndPreview({ themeColorPresets, setThemeColorPresets }) {
  return (
    <SoftBox>
      <RadioGroup
        name="themeColorPresets"
        value={themeColorPresets}
        onChange={(e) => setThemeColorPresets(e.target.value)}
        sx={{ my: 2 }}
      >
        <Stack direction={{ xs: "row" }} justifyContent="center" spacing={1}>
          {colorOption.map((color) => {
            const colorName = color.name;
            const colorValue = color.value;
            const isSelected = themeColorPresets === colorName;

            return (
              <Tooltip key={colorName} title={capitalCase(colorName)} placement="right">
                <CardActionArea
                  sx={{ color: colorValue, borderRadius: "50%", width: 32, height: 32 }}
                >
                  <Box
                    sx={{
                      width: 1,
                      height: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      ...(isSelected && {
                        borderStyle: "solid",
                        borderWidth: 4,
                        borderColor: alpha(colorValue, 0.24),
                      }),
                    }}
                  >
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        bgcolor: colorValue,
                        ...(isSelected && {
                          width: 14,
                          height: 14,
                          transition: (theme) =>
                            theme.transitions.create("all", {
                              easing: theme.transitions.easing.easeInOut,
                              duration: theme.transitions.duration.shorter,
                            }),
                        }),
                      }}
                    />
                    <FormControlLabel
                      label=""
                      value={colorName}
                      control={<Radio sx={{ display: "none" }} />}
                      sx={{
                        top: 0,
                        left: 0,
                        margin: 0,
                        width: 1,
                        height: 1,
                        position: "absolute",
                      }}
                    />
                  </Box>
                </CardActionArea>
              </Tooltip>
            );
          })}
        </Stack>
      </RadioGroup>
      <Preview themeColorPresets={themeColorPresets} />
    </SoftBox>
  );
}

export default ColorAndPreview;

// Typechecking props of the SettingsColorPresets
ColorAndPreview.propTypes = {
  themeColorPresets: PropTypes.string,
  setThemeColorPresets: PropTypes.func,
};
