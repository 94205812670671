import { Box } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import PaymentOptionScreen from "./PaymentOptionScreen";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Info from "./Info";
import Status from "./Status";

const Page = {
  PaymentOption: 1,
  Info: 2,
  Status: 3,
};

const Preview = ({ themeColorPresets }) => {
  const [page, setPage] = useState(Page.PaymentOption);
  const btnColor = ["default", "yellow", "cyan"].includes(themeColorPresets) ? "#4E4E4E" : "#fff";

  const onNext = () => {
    switch (page) {
      case Page.PaymentOption:
        setPage(Page.Info);
        break;
      case Page.Info:
        setPage(Page.Status);
        break;
      case Page.Status:
        setPage(Page.PaymentOption);
        break;
    }
  };
  const onBack = () => {
    switch (page) {
      case Page.PaymentOption:
        setPage(Page.Status);
        break;
      case Page.Info:
        setPage(Page.PaymentOption);
        break;
      case Page.Status:
        setPage(Page.Info);
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          overflow: "visible",
          width: "90%",
          top: "5%",
          boxShadow: "rgba(145, 158, 171, 0.16) 0px 24px 48px 0px",
          backgroundColor: themeColorPresets,
        }}
        py={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <SoftBox
          ml={2}
          sx={{
            backgroundColor: "rgba(214, 212, 213, 0.21)",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            border: "none",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          onClick={onBack}
          type="text"
        >
          <ArrowBackIosIcon sx={{ ml: 1, color: btnColor }} />
        </SoftBox>
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            backgroundColor: themeColorPresets,
          }}
        >
          {page === Page.PaymentOption && (
            <PaymentOptionScreen themeColorPresets={themeColorPresets} />
          )}
          {page === Page.Info && <Info themeColorPresets={themeColorPresets} />}
          {page === Page.Status && <Status themeColorPresets={themeColorPresets} />}
        </Box>
        <SoftBox
          mr={2}
          sx={{
            backgroundColor: "rgba(214, 212, 213, 0.21)",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            border: "none",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          onClick={onNext}
          type="text"
        >
          <ArrowForwardIosIcon sx={{ color: btnColor }} />
        </SoftBox>
      </Box>
    </Box>
  );
};
export default Preview;

Preview.propTypes = {
  themeColorPresets: PropTypes.string,
};
